"use client";
import { MenuItemType } from "@/types/MenuTypes";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { useState } from "react";

type propsType = {
    item: MenuItemType;
};

export default function Dropdown(props: Readonly<propsType>) {
    const { item } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const menuItems = item?.children ? item.children : [];
    const classState = isOpen ? "flex" : "hidden";

    let lastGroupId: number | undefined = 1;

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const renderSubMenu = (item: MenuItemType) => {
        let isNewGroup = false;

        if (item.subMenuGroupId !== lastGroupId) {
            isNewGroup = true;
            lastGroupId = item.subMenuGroupId;
        }

        return (
            <Link
                key={item.route}
                className={`bg-white hover:bg-gray-300 px-4 py-1 ${isNewGroup && "border-t border-gray-300"}`}
                href={item?.route ?? "#"}
                onClick={toggle}
                target="_blank">
                {item.title}
            </Link>
        );
    };

    return (
        <>
            <div className="relative ">
                <button
                    className="hover:cursor-pointer"
                    onClick={toggle}>
                    {item.title}
                </button>

                <FontAwesomeIcon
                    icon={isOpen ? faCaretUp : faCaretDown}
                    size="xs"
                    className="px-2"
                />
                <div
                    className={`absolute bg-white top-6 z-30 w-[250px] flex flex-col gap-0.5 py-2 text-gray-800 border-b shadow-md rounded-sm ${classState}`}>
                    {menuItems.map((item) => renderSubMenu(item))}
                </div>
            </div>
            {isOpen ? (
                <button
                    className="fixed top-0 right-0 bottom-0 left-0 z-20"
                    onClick={toggle}></button>
            ) : (
                <></>
            )}
        </>
    );
}
